import { Textarea } from "baseui/textarea";
import { Client as Styletron } from "styletron-engine-atomic";
import { FormControl } from "baseui/form-control";
import { Label1 } from "baseui/typography";
import { ProgressBar } from "baseui/progress-bar";
import { Tag } from "baseui/tag";
import { StyledLink } from "baseui/link";
import { ListItem, ListItemLabel } from "baseui/list";

import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { AppNavBar } from "baseui/app-nav-bar";
import { ChevronDown, Delete } from "baseui/icon";
import { LightTheme, DarkTheme } from "baseui";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { Table } from "baseui/table-semantic";

import useDrivePicker from "react-google-drive-picker";
import { Card, StyledBody } from "baseui/card";

import "./App.css";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  GoogleLogout,
} from "react-google-login";
import { Dispatch, useCallback, useEffect, useReducer, useState } from "react";
import { OAuth2Client } from "google-auth-library";
import {
  GoogleSpreadsheet,
  GoogleSpreadsheetRow,
  GoogleSpreadsheetWorksheet,
} from "google-spreadsheet";
import React from "react";
import { Block } from "baseui/block";

const developerKey = "AIzaSyDwqJUY2DYAee4Idqova6c2OP3IdYdMr_4";
const oauthClient = new OAuth2Client({
  clientId:
    "786702275857-6at6j3lpng2kpgcb6ktv06vv73bu4v4k.apps.googleusercontent.com",
  clientSecret: "kalA9CpOgPVST7-1zNYz2WZ8",
  redirectUri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://headlines-counter.netlify.app",
});

const getRefreshToken = () => localStorage.getItem("rt");
const setRefreshToken = (refreshToken: string) =>
  localStorage.setItem("rt", refreshToken);

const initialState: State = {
  isAuthorized: false,
  headline: "",
  keyword: "",
  stats: {
    initial: [
      {
        keyword: "Calculating...",
        // titles: [""],
        skipCount: 0,
        negativeCount: 0,
      },
    ],
  },
  selectedFiles: [],
};
type State = {
  isAuthorized: boolean;
  document?: { title: string; sheet: GoogleSpreadsheetWorksheet };
  headline: string;
  progress?: number;
  keyword: string;
  isCompleted?: boolean;
  stats: { [sheetId: string]: Stat[] };
  selectedFiles: File[];
  finalStats?: Stat[];
};

type LoginAction = {
  type: "login";
};
type LogoutAction = {
  type: "logout";
};

type OpenFile = {
  type: "open_file";
  title: string;
  sheet: GoogleSpreadsheetWorksheet;
};

type CloseFile = {
  type: "close_file";
};

type Stat = {
  keyword: string;
  skipCount: number;
  negativeCount: number;
  // titles: string[];
};
type SetStats = {
  type: "set_stats";
  sheetId: string;
  stats: Stat[];
};

type File = {
  id: string;
  url: string;
  name: string;
};

type SelectFiles = {
  type: "select_files";
  files: File[];
};
type SetFinalStats = {
  type: "set_final_stats";
  stats: Stat[];
};

type SetHeadline = {
  type: "set_headline";
  headline: string;
  progress: number;
  keyword: string;
  isCompleted?: boolean;
};

type Actions =
  | LoginAction
  | LogoutAction
  | OpenFile
  | CloseFile
  | SetHeadline
  | SetStats
  | SelectFiles
  | SetFinalStats;

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case "login":
      return { ...state, isAuthorized: true };
    case "logout":
      return { ...state, isAuthorized: false };
    case "open_file":
      return {
        ...state,
        document: { title: action.title, sheet: action.sheet },
      };
    case "close_file":
      return { ...state, document: undefined, progress: undefined };
    case "set_stats":
      return {
        ...state,
        stats: { ...state.stats, [action.sheetId]: action.stats },
      };
    case "set_headline":
      return {
        ...state,
        headline: action.headline,
        progress: action.progress,
        keyword: action.keyword,
        isCompleted: action.isCompleted,
      };
    case "select_files":
      return { ...state, selectedFiles: action.files };
    case "set_final_stats":
      return { ...state, finalStats: action.stats };
    default:
      throw new Error();
  }
}
type Theme = "light" | "dark";

function App() {
  const [theme, setTheme] = React.useState<Theme>("dark");
  (document.body as any).style = `background: ${
    theme === "light" ? "#fff" : "#000"
  };`;

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme") as Theme;
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  console.log(state.selectedFiles);

  const getCurrentHeadline = useCallback(
    (sheet: GoogleSpreadsheetWorksheet) => {
      const index = getActiveIndex(sheet);

      if (index === -1) {
        return {
          headline: "",
          progress: 100,
          keyword: "",
        };
      }
      const progress = (index * 100) / sheet.rowCount;
      const keyword = sheet.getCell(index, 0).value;
      const headline = sheet.getCell(index, 1).value;

      return {
        headline,
        progress,
        keyword,
      };
    },
    []
  );

  const calculateAll = async (keywordsString: string) => {
    const keywords = keywordsString.split(/\n/);
    const result = await Promise.all(
      state.selectedFiles.map(async (f) => {
        const doc = new GoogleSpreadsheet(f.id);
        doc.useOAuth2Client(oauthClient);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        await sheet.loadCells();
        return calculateStats2(sheet, keywords);
      })
    );

    const finalResults = result.reduce((prev, current) =>
      prev.map((item, index) => ({
        keyword: item.keyword,
        skipCount: item.skipCount + current[index].skipCount,
        negativeCount: item.negativeCount + current[index].negativeCount,
      }))
    );

    dispatch({ type: "set_final_stats", stats: finalResults });

    console.log("result", result);
    console.log("final result", finalResults);
  };

  const calculateStats2 = async (
    sheet: GoogleSpreadsheetWorksheet,
    keywords: string[]
  ) => {
    const allRows = await sheet.getRows();

    const stats = keywords.map((k) => {
      const skipTitles = allRows
        .filter(
          (r) =>
            r["Keyword"].toLowerCase().startsWith(k.toLowerCase()) &&
            sheet.getCell(r.rowIndex - 1, 4).value === "skip"
        )
        .map(mapResult);

      const negativeTitles = allRows
        .filter(
          (r) =>
            r["Keyword"].toLowerCase().includes(k.toLowerCase()) &&
            sheet.getCell(r.rowIndex - 1, 4).value === "negative"
        )
        .map(mapResult);

      return {
        keyword: k,
        skipCount: skipTitles.length,
        negativeCount: negativeTitles.length,
        // titles: skipTitles.concat(negativeTitles),
      };
    });

    return [...stats];
  };

  // const calculateStats = async (sheet: GoogleSpreadsheetWorksheet) => {
  //   const allRows = await sheet.getRows();
  //   const keywords = allRows
  //     .map((r) => r["Keywords"] as string)
  //     .filter((r) => !!r);

  //   const stats = keywords.map((k) => {
  //     const skipTitles = allRows
  //       .filter(
  //         (r) =>
  //           r["Keyword"].toLowerCase().includes(k.toLowerCase()) &&
  //           r["Result"] === "skip"
  //       )
  //       .map(mapResult);
  //     const negativeTitles = allRows
  //       .filter(
  //         (r) =>
  //           r["Keyword"].toLowerCase().includes(k.toLowerCase()) &&
  //           r["Result"] === "negative"
  //       )
  //       .map(mapResult);

  //     return {
  //       keyword: k,
  //       skipCount: skipTitles.length,
  //       negativeCount: negativeTitles.length,
  //       titles: skipTitles.concat(negativeTitles),
  //     };
  //   });

  //   const total = {
  //     keyword: "Total",
  //     negativeCount: stats
  //       .map((s) => s.negativeCount)
  //       .reduce((p, c) => p + c, 0),
  //     skipCount: stats.map((s) => s.skipCount).reduce((p, c) => p + c, 0),
  //     titles: allRows.filter((v) => !!v).map(mapResult),
  //   };
  //   return [total, ...stats];
  // };

  const mapResult = (r: GoogleSpreadsheetRow) =>
    `${r["Keyword"]} ${r["Result"]} ${r["Title"]}`;

  const setHeadline = useCallback(
    async (sheet: GoogleSpreadsheetWorksheet) => {
      const { headline, progress, keyword } = await getCurrentHeadline(sheet);

      if (!headline) {
        dispatch({
          type: "set_headline",
          headline: "",
          progress: progress,
          keyword: "",
          isCompleted: true,
        });
        return;
      }

      dispatch({
        type: "set_headline",
        headline: headline?.toString() || "",
        progress: progress,
        keyword: keyword.toString() || "",
      });
    },
    [getCurrentHeadline]
  );

  const openFile = useCallback(
    async (sheetId: string) => {
      localStorage.setItem("sheetId", sheetId);
      const doc = new GoogleSpreadsheet(sheetId!);
      doc.useOAuth2Client(oauthClient);
      await doc.loadInfo();
      const sheet = doc.sheetsByIndex[0];
      await sheet.loadCells();
      // await setHeader(sheet);
      dispatch({ type: "open_file", title: doc.title, sheet: sheet });
      await setHeadline(sheet);
      // const stats = await calculateStats(sheet);
      // dispatch({ type: "set_stats", stats, sheetId: sheet.sheetId });
    },
    [setHeadline]
  );

  // const setHeader = async (sheet: GoogleSpreadsheetWorksheet) => {
  //   sheet.getCell(0, 4).value = "Result";
  //   sheet.getCell(0, 5).value = "Keywords";
  //   await sheet.saveUpdatedCells();
  // };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  useEffect(() => {
    if (getRefreshToken()) {
      initClient();
      dispatch({ type: "login" });

      const sheetId = localStorage.getItem("sheetId");
      if (sheetId) {
        openFile(sheetId);
      }
    }
  }, [openFile]);

  const [openPicker, data] = useDrivePicker();
  const [openPicker2, data2] = useDrivePicker();

  useEffect(() => {
    if (data && data.docs.length > 0) {
      openFile(data.docs[0].id);
    }
  }, [data, openFile]);

  useEffect(() => {
    if (data2 && data2.docs.length > 0) {
      dispatch({
        type: "select_files",
        files: data2.docs.map((f) => ({ id: f.id, url: f.url, name: f.name })),
      });
    }
  }, [data2]);

  const onOpenPicker = () => {
    openPicker({
      token: oauthClient.credentials.access_token!,
      clientId: oauthClient._clientId!,
      developerKey: developerKey,
      viewId: "SPREADSHEETS",
      supportDrives: true,
      // multiselect: false,
    });
  };

  const onOpenPickerMultiple = () => {
    openPicker2({
      token: oauthClient.credentials.access_token!,
      clientId: oauthClient._clientId!,
      developerKey: developerKey,
      viewId: "SPREADSHEETS",
      supportDrives: true,
      multiselect: true,
    });
  };

  const closeFile = () => {
    dispatch({ type: "close_file" });
    localStorage.removeItem("sheetId");
  };

  const getActiveIndex = (
    sheet: GoogleSpreadsheetWorksheet,
    column: number = 4
  ) => {
    for (let index = 1; index < sheet.rowCount; index++) {
      const value = sheet.getCell(index, column).value;
      if (!value) {
        return index;
      }
    }
    return -1;
  };

  const setState = async (type: "skip" | "negative" | "ignore") => {
    const sheet = state.document?.sheet!;
    const index = getActiveIndex(sheet);
    sheet.getCell(index, 4).value = type;
    await sheet.getCell(index, 4).save();
    // set unsaved status
    await setHeadline(sheet);
  };

  const engine = new Styletron();

  // const addKeyword = async (keyword: string) => {
  //   const sheet = state.document?.sheet!;
  //   const index = getActiveIndex(sheet, 5);
  //   const cell = sheet.getCell(index, 5);
  //   cell.value = keyword;
  //   await cell.save();
  //   const stats = await calculateStats(sheet);
  //   dispatch({ type: "set_stats", stats: stats, sheetId: sheet.sheetId });
  // };

  const Processing = () => {
    return state.isCompleted ? (
      <h1> &#127937; File completed!</h1>
    ) : (
      <Block>
        <Card>
          <Label1>{state.keyword}</Label1>
          <StyledBody>{state.headline}</StyledBody>
        </Card>

        <ProgressBar value={state.progress} />
        <div style={{}}>
          <ButtonGroup>
            <Button onClick={() => setState("skip")}>Skip</Button>
            <Button onClick={() => setState("negative")}>Negative</Button>
            <Button onClick={() => setState("ignore")}>Ignore</Button>
          </ButtonGroup>
        </div>
      </Block>
    );
  };

  const Counting = () => {
    const [keywords, setKeywords] = useState("");

    return (
      <Card>
        <Label1>Count results</Label1>

        <>
          <FormControl
            label="Provide keywords to count"
            caption="Specify full or partial words one per line"
          >
            <Textarea
              value={keywords}
              size="large"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setKeywords(e.target.value)
              }
              placeholder="good&#10;bad&#10;..."
            />
          </FormControl>
          <Button
            disabled={!keywords.trim()}
            onClick={() => calculateAll(keywords)}
          >
            Calculate
          </Button>
        </>

        {state.selectedFiles.map((f) => (
          <ListItem
            endEnhancer={() => (
              <StyledLink target="_blank" href={f.url}>
                Open
              </StyledLink>
            )}
          >
            <ListItemLabel>{f.name}</ListItemLabel>
          </ListItem>
        ))}

        {state.finalStats ? <Results stats={state.finalStats} /> : null}
      </Card>
    );
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme === "light" ? LightTheme : DarkTheme}>
        <Navigation />
        {state.isAuthorized ? (
          <Block>
            {state.document || state.selectedFiles.length > 0 ? (
              state.document ? (
                <Block>
                  <Tag onActionClick={closeFile}>{state.document.title}</Tag>

                  {/* <KeywordForm addKeyword={addKeyword} /> */}
                  <Processing />
                </Block>
              ) : state.selectedFiles.length > 0 ? (
                <Counting />
              ) : null
            ) : (
              <ButtonGroup>
                <Button onClick={onOpenPicker}>Processing</Button>
                <Button onClick={onOpenPickerMultiple}>Counting</Button>
              </ButtonGroup>
            )}
          </Block>
        ) : null}
      </BaseProvider>
    </StyletronProvider>
  );

  function Results(props: { stats: Stat[] }) {
    return (
      <Table
        columns={["Keyword", "Negative count", "Skip count"]}
        data={props.stats.map((k) => [k.keyword, k.negativeCount, k.skipCount])}
      />
    );
  }

  // function KeywordForm(props: { addKeyword: (keyword: string) => void }) {
  //   const [keyword, setKeyword] = useState("");
  //   const submit = () => {
  //     props.addKeyword(keyword);
  //     setKeyword("");
  //   };

  //   return (
  //     <Block display={state.isCompleted ? "block" : "none"}>
  //       <Card>
  //         <Label1>Results</Label1>
  //         <StyledBody>
  //           <Accordion accordion>
  //             {(
  //               state.stats[state.document?.sheet.sheetId!] ||
  //               state.stats.initial
  //             ).map((s) => (
  //               <Panel
  //                 title={`${s.keyword.toUpperCase()} ${`skip(${s.skipCount}) negative(${s.negativeCount})`}`}
  //               >
  //                 ...
  //               </Panel>
  //             ))}
  //           </Accordion>
  //           <Button>Copy results</Button>
  //           <FormControl
  //             label="Add keyword"
  //             caption="Specify full word or part of it"
  //           >
  //             <Input
  //               value={keyword}
  //               onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
  //                 setKeyword(e.target.value)
  //               }
  //               placeholder="good"
  //             />
  //           </FormControl>
  //         </StyledBody>
  //         <StyledAction>
  //           <Button onClick={submit}>Add</Button>
  //         </StyledAction>
  //       </Card>
  //     </Block>
  //   );
  // }

  function Navigation() {
    const [mainItems] = React.useState([
      {
        // active: true,
        icon: ChevronDown,
        label: "Main B",

        navExitIcon: Delete,
      },
    ]);
    return (
      <AppNavBar
        title="Headlines"
        mainItems={mainItems}
        // userItems={[{ label: "login" }]}
        mapItemToNode={(item) => {
          return (
            <Auth
              state={state}
              dispatch={dispatch}
              theme={theme}
              toggleTheme={() => {
                setTheme((theme) => (theme === "light" ? "dark" : "light"));
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              }}
            />
          );
        }}
        // username="Umka Marshmallow"
        // usernameSubtitle="5 Stars"
        // userItems={[
        //   { icon: Overflow, label: "User A" },
        //   { icon: Overflow, label: "User B" },
        // ]}
        onUserItemSelect={(item) => console.log(item)}
      />
    );
  }
}

function Auth(props: {
  state: State;
  dispatch: Dispatch<Actions>;
  theme: "light" | "dark";
  toggleTheme: () => void;
}) {
  const onLoginSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    console.log({ response });
    const result = await oauthClient.getToken(response.code!);
    console.log({ result });
    setRefreshToken(result.tokens.refresh_token || "");
    initClient();
    props.dispatch({ type: "login" });
  };

  const onLoginFailure = (error: any) => {
    console.log(error);
  };

  const onLogoutSuccess = () => {
    setRefreshToken("");
    props.dispatch({ type: "logout" });
    oauthClient.setCredentials({});
  };

  return (
    <Block>
      <Button onClick={props.toggleTheme}>{props.theme}</Button>
      {props.state.isAuthorized ? (
        <GoogleLogout
          render={({ onClick }) => <Button onClick={onClick}>Logout</Button>}
          buttonText="Logout"
          onLogoutSuccess={onLogoutSuccess}
          clientId={oauthClient._clientId!}
        />
      ) : (
        <GoogleLogin
          theme={props.theme}
          clientId={oauthClient._clientId!}
          buttonText="Login"
          prompt={"consent"}
          onSuccess={onLoginSuccess}
          responseType={"code"}
          accessType={"offline"}
          // scope="https://www.googleapis.com/auth/drive.file"
          scope="https://www.googleapis.com/auth/spreadsheets"
          onFailure={onLoginFailure}
          cookiePolicy={"single_host_origin"}
        />
      )}
    </Block>
  );
}

const initClient = () => {
  const refreshToken = getRefreshToken();
  oauthClient.setCredentials({ refresh_token: refreshToken });

  oauthClient.on("tokens", (credentials) => {
    if (credentials.refresh_token) {
      setRefreshToken(credentials.refresh_token);
    }
  });
};
export default App;
